import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import { eraseCookie, getCookie } from "../service/helper"

const PaymentFailed = (props) => {

    const isUserLoggedIn = getCookie("login_id")

    const Relogin = () => {
        if (isUserLoggedIn) {
            eraseCookie("login_id")
            eraseCookie("user")
            eraseCookie("hs")
            navigate('/')
            if (typeof window !== "undefined") setTimeout(() => { window.location.reload() },1000)
        }
    }

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        '/assets/css/services-detail.css', 
                        '/assets/css/about-through-the-ages.css', 
                        '/assets/css/accessibility.css',
                        '/assets/css/common.css',
                        '/assets/css/payment-success.css',
                    ]
                }}
                tags={[]}
            />
            <main class="innerpage">
                <section class="section_bg paymentSuccess paymentFailed">
                    <div class="container">
                        <span class="booking-confirmed-icon">
                            <img src="/assets/images/transaction-failed-icon.svg" alt="Transaction Failed Icon" />
                        </span>
                        <h4>Transaction Failed</h4>
                        <div class="successText">
                            <p>Please try again.</p>
                            <p>If the amount has been deducted from your account, <br/> please contact us at <a href="mailto:info@hindujahospital.com">info@hindujahospital.com</a>.</p>
                        </div>
                        {/* <div class="bottomText">
                            <a href="javascript:;" onClick={Relogin} class="btn btn-primary">LOGIN NOW</a>
                        </div> */}
                        <div class="instructionList">
                            <span class="topHead">Your transaction could have failed if:</span>
                            <ol>
                                <li>Your internet connectivity is unstable.</li>
                                <li>There was some temporary internal error from the banks end.</li>
                                <li>Transaction window was kept idle on banks portal while / before submitting card details.</li>
                                <li>International debit/ credit card was used.</li>
                                <li>Your internet connectivity is unstable.</li>
                                <li>Either back or refresh button was used accidentally/intentionally.</li>
                                <li>Insufficient funds on card or some other restriction from the participating banks.</li>
                            </ol>  
                        </div>  
                    </div>  
                </section> 
            </main>
        </Layout>
    )
}

export default PaymentFailed